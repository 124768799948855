import useModal from "Hooks/useModal";
import propTypes from "prop-types";
import { useState, useEffect } from "react";
import LicenceInfo from "../LicenceInfo/LicenceInfo";
import Markdown from "../Markdown/Markdown";
import PackagePreview from "../PackagePreview/PackagePreview";
import PreLicenseCertificateList from "../PreLicenceList/PreLicenceList";
import Typography from "../Typography/Typography";
import styles from "./LicensingView.module.css";
import { usePropertyDetails } from "context/propertyContext/PropertyContext";
import { Licensing } from "components";

const buildingAdditionalLicence = `
This building needs an Additional (S257) licence if you are running a Section 257 HMO.
<br/>
257 HMO refers to buildings that:
- Have been converted into self-contained flats; and
- Less than two-thirds of the flats are owner-occupied; and
- The conversion did not comply with the relevant Building Regulations in force at that time and still does not comply. 

If the conversion was completed before 1 June 1992, it should comply with Building Regulations in force as of 1 June 1992. If the building was converted after 1 June 1992, it should comply with the Building Regulations in force at the time of the conversion.
<br/>
If this is the case the HMO Management Regulations 2007 applies to your property and you should ensure you comply as the council will still enforce this as well as the Regulatory Reform Order 2005 (where applicable) and much more.`;

const flatMandatoryLicenceMarkdown = {
  EnglandWales: `**England and Wales:**
<br/>
This property needs an Mandatory HMO licence when running a Section 254 HMO.
<br/>
Note:

A resident landlord (owner) and family may share a house with one or two unrelated lodgers without the need for a licence. A third lodger means the house is an HMO and it must be licensed.
<br/>`,
  Scotland: `**Scotland:**
<br/>
This property needs an HMO Licence when running an HMO.`,
};

const houseMandatoryLicenceMarkdown = `
This property needs a Mandatory HMO licence.
<br/>
Note:

A resident landlord (owner) and family may share a house with one or two unrelated lodgers without the need for a licence. A third lodger means the house is an HMO and it must be licensed.`;

const flatMandatoryDisclaimerLicenceMarkdown = {
  England: `**England Only:**

The English government have decided to exclude purpose built self-contained flats within a block comprising three or more self-contained flats from the mandatory HMO licensing scheme. 
<br/>
Where there is Additional or Selective licensing in the area you may still qualify for one of these licences.`,
};

const houseMandatoryDisclaimerLicenceMarkdown = {
  England: `**England Only:**

The English government have decided to exclude purpose built self-contained flats within a block comprising three or more self-contained flats from the mandatory HMO licensing scheme. 
<br/>
Where there is Additional or Selective licensing in the area you may still qualify for one of these licences.`,
};

const flatAdditionalLicenceMarkdown = `
This property needs an Additional HMO licence when running a Section 254 HMO.
<br/>
Note:

A resident landlord (owner) and family may share a house with one or two unrelated lodgers without the need for a licence. A third lodger means the house is an HMO and it must be licensed.`;

const houseAdditionalLicenceMarkdown = {
  EnglandWales: `**England Wales:**

This property needs an Additional HMO licence when running a Section 254 HMO.
<br/>Note:

A resident landlord (owner) and family may share a house with one or two unrelated lodgers without the need for a licence. A third lodger means the house is an HMO and it must be licensed.`,

  Scotland: `**Scotland:**

This property needs an HMO Licence when running an HMO.
<br/>
Note:

A resident landlord (owner) and family may share a house with one or two unrelated lodgers without the need for a licence. A third lodger means the house is an HMO and it must be licensed.`,
};
const flatSelectiveLicenceMarkdown = {
  England: `This property needs a Selective licence.
<br/>
Note:
A resident landlord (owner) and family may share a house with one or two unrelated lodgers without the need for a licence. A third lodger means the house is an HMO and it must be licensed.`,
};
const houseSelectiveLicenceMarkdown = {
  England: `This property needs a Selective licence.
<br/>
Note:

A resident landlord (owner) and family may share a house with one or two unrelated lodgers without the need for a licence. A third lodger means the house is an HMO and it must be licensed.`,
};

const LicensingView = (props) => {
  const [isRiskModalOpen, openRiskModal, closeRiskModal] = useModal();
  const [state, setState] = useState({
    showLicensing: false,
  });
  const { showLicensing } = state;
  const handleModal = (modal, value) => {
    setState((state) => ({ ...state, [modal]: !value }));
  };
  const [MarkdownText, setMarkdownText] = useState();
  const [MarkdownTextEngland, setMarkdownTextEngland] = useState();
  const propertyDetails = usePropertyDetails();
  useEffect(() => {
    if (propertyDetails.countryOnsCode) {
      if (
        propertyDetails.countryOnsCode[0] === "E" ||
        propertyDetails.countryOnsCode[0] === "W"
      ) {
        return setMarkdownText("EnglandWales");
      }
      if (propertyDetails.countryOnsCode[0] === "S") {
        return setMarkdownText("Scotland");
      }
    } else {
      return setMarkdownText("England");
    }
  }, [propertyDetails.countryOnsCode]);

  const jsToUKDate = (date) =>
    date.split("T")[0].split("-").reverse().join("/");

  const getLicenceTitle = (title, licence) => {
    return title.includes("Licensing") ? title : `${title} Licence`;

    if (licence && licence.state && licence.state.includes("Coming")) {
      licence.startDate
        ? (title = `${title} (Coming soon: ${jsToUKDate(licence.startDate)})`)
        : (title = `${title} (Coming soon)`);
    }
    return title;
  };
  return (
    <div className={styles.container}>
      <header>
        <Typography as="h1">
          {getLicenceTitle(props.title, props.licence)}
        </Typography>
        <span>
          <PackagePreview
            openPackageModal={props.openPackageModal}
            price={props.package?.price || ""}
            currentPackage={props.package?.currentPackage}
          />
        </span>
      </header>

      <Typography>{props.licence.schemeTitle || null}</Typography>
      <main>
        {console.log(props)}
        {props.propertyDetails.propertyType === "Building" &&
          props.title === "Additional HMO (257)" && (
            <Markdown information={buildingAdditionalLicence} />
          )}
        {props.propertyDetails.propertyType === "Flat" && (
          <>
            {props.title === "Mandatory HMO" && (
              <Markdown
                information={flatMandatoryLicenceMarkdown[MarkdownText]}
              />
            )}
            {props.title === "Additional HMO (254)" && (
              <Markdown information={flatAdditionalLicenceMarkdown} />
            )}
            {props.title === "Selective" && (
              <Markdown
                information={flatSelectiveLicenceMarkdown[MarkdownTextEngland]}
              />
            )}
          </>
        )}
        {props.propertyDetails.propertyType === "House" && (
          <>
            {props.title === "Mandatory HMO" && (
              <Markdown information={houseMandatoryLicenceMarkdown} />
            )}
            {props.title === "Additional HMO (254)" && (
              <Markdown
                information={houseAdditionalLicenceMarkdown[MarkdownText]}
              />
            )}
            {props.title === "Selective" && (
              <Markdown
                information={houseSelectiveLicenceMarkdown[MarkdownTextEngland]}
              />
            )}
          </>
        )}
        {props.guideline.map((rule, index) =>
          rule.split(";").map((ruleDescription, index) => {
            if (index === 0) {
              return <Typography key={index}>{ruleDescription}</Typography>;
            }
            return (
              <Typography key={index}>{`• ${ruleDescription}`}</Typography>
            );
          })
        )}
        {props.propertyDetails.propertyType === "Flat" && (
          <>
            {props.title === "Mandatory HMO" && (
              <Markdown
                information={
                  flatMandatoryDisclaimerLicenceMarkdown[MarkdownTextEngland]
                }
              />
            )}
          </>
        )}
        {props.propertyDetails.propertyType === "House" && (
          <>
            {props.title === "Mandatory HMO" && (
              <Markdown
                information={
                  houseMandatoryDisclaimerLicenceMarkdown[MarkdownTextEngland]
                }
              />
            )}
          </>
        )}
        {propertyDetails.CRMUniqueIdentifier ? null : (
          <LicenceInfo
            isIssueDateRequired={false}
            isExpiryDateRequired={true}
            emptyMessage="Upload your application reference, proposed licence or issued licence if you have one."
            documentTypes={props.documentTypes}
            documentName={props.title}
            licence={props.licence}
            canUploadFiles={props.canUploadFiles}
          />
        )}

        <PreLicenseCertificateList
          description={props.preLicenceDescription}
          certificates={props.certificates}
          actions={props.certificateActions}
          canUploadFiles={props.canUploadFiles}
        />

        <Licensing
          isOpen={showLicensing}
          onClose={() => handleModal("showLicensing", showLicensing)}
        />

        {/* Scheme Pricing Markdown */}
        <Markdown
          information={
            props.propertyDetails?.tenancyLicences?.currentSchemes.find(
              (l) => l.type === props.licenceType
            )?.schemePricing
          }
        />
      </main>
    </div>
  );
};

LicensingView.propTypes = {
  title: propTypes.string.isRequired,
  package: propTypes.shape({
    price: propTypes.string,
    currentPackage: propTypes.string,
  }),
  headingText: propTypes.string,
  subHeadingText: propTypes.string,
  guideline: propTypes.arrayOf(propTypes.string),
  documentTypes: LicenceInfo.propTypes.documentTypes,
  licence: LicenceInfo.propTypes.licence,
  certificates: PreLicenseCertificateList.propTypes.certificates,
  certificateActions: propTypes.shape(
    PreLicenseCertificateList.propTypes.actions
  ),
  preLicenceDescription: propTypes.string.isRequired,
  canUploadFiles: propTypes.bool,
};

LicensingView.defaultProps = {
  guideline: [""],
};

export default LicensingView;
